
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    TmButton,
    TmAlert,
    TmModal,
    TmFormLine,
  },
  setup() {
    const status = ref('Active')
    const isEditable = ref(false)
    const statusList = ref([
      'Active',
      'Disabled',
    ])
    const minBalance = ref('$20.00')
    const rechargeAmount = ref('$100.00')
    const amountList = ref([
      '$10.00',
      '$15.00',
      '$25.00',
      '$50.00',
      '$100.00',
      '$250.00',
      '$500.00',
      '$1000.00',
      '$2500.00',
      '$5000.00',
    ])

    return {
      status,
      statusList,
      minBalance,
      rechargeAmount,
      isEditable,
      amountList,
    }
  },
})
